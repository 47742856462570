
import { defineComponent } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
  },
  setup() {
    return {
      getIllustrationsPath,
    };
  },
});
